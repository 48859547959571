import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import "regenerator-runtime/runtime";
import { userActions } from "./http";

export type SciWormAuthConfig = {
  client: string;
  tenant: string;
};

function msalConfiguration(config: SciWormAuthConfig): Configuration {
  return {
    auth: {
      clientId: config.client,
      authority: "https://login.microsoftonline.com/" + config.tenant,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: never, containsPii: never) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.debug(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  };
}

async function signIn() {
  const state = encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
  const loginRequest: RedirectRequest = {
    scopes: ["email", "profile", "openid"],
    state,
  };
  await msalInstance.loginRedirect(loginRequest);
}

const CONFIG_KEY = "authConfig";
export const CONFIG_ENDPOINT = "/swapi/authentication/config";

let msalInstance: PublicClientApplication;

export const auth = {
  config: async () => {
    const savedConfig = sessionStorage.getItem(CONFIG_KEY);
    if (savedConfig) {
      return JSON.parse(savedConfig);
    }
    const res = await fetch(CONFIG_ENDPOINT);
    const config = await res.json();
    sessionStorage.setItem(CONFIG_KEY, JSON.stringify(config));
    return config;
  },
  initialize: async (config: SciWormAuthConfig) => {
    msalInstance = new PublicClientApplication(msalConfiguration(config));
    const authenticationResult = await msalInstance.handleRedirectPromise();
    if (authenticationResult) {
      // It's not so critical to stop application from loading
      await userActions.saveUserLogging().catch(() => {});
      // redirect to the page where user started
      if (authenticationResult.state) {
        const originalUri = decodeURIComponent(authenticationResult.state);
        window.location.replace(originalUri);
      }
    } else {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        await signIn();
      }
    }
  },
  acquireToken: async () => {
    if (!msalInstance) {
      throw new Error("Authentication library not initialized");
    }
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      await signIn();
    }
    const request = {
      account: accounts[0],
      scopes: ["email", "profile", "openid"],
    };
    const tokenResponse = await msalInstance.acquireTokenSilent(request);
    return tokenResponse.idToken;
  },
  acquireInitials: async () => {
    if (!msalInstance) {
      throw new Error("Authentication library not initialized");
    }
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      await signIn();
    }
    return accounts[0].username.split("@")[0].toUpperCase();
  },
};

// for tests only
export function resetSciwormAuth() {
  msalInstance = undefined;
}
