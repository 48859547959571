import { appLoader } from "./loader";

function showLoader(id: string) {
  const el = Object.assign(document.createElement("div"), {
    id: `single-spa-loader:${id}`,
  });
  el.className += "single-spa-loader";
  el.innerHTML = appLoader();
  document.body.appendChild(el);
}

function removeLoader(id: string) {
  document.getElementById(`single-spa-loader:${id}`).remove();
}

export function loadApp(id: string) {
  return Promise.resolve()
    .then(() => {
      showLoader(id);
      return import(/* @vite-ignore */ id);
    })
    .then((app) => {
      removeLoader(id);
      return app;
    });
}
