import {
  addErrorHandler,
  AppError,
  registerApplication,
  start,
  unregisterApplication,
  removeErrorHandler,
} from "single-spa";
import { sciwormBiolinkLocation, notSciwormBiolinkLocation } from "./apps/sciworm-biolink";
import { loadError, removeError } from "./service/error";
import { auth } from "./service/auth";
import { directAccessRoutingHandler } from "./direct-access";
import { initializeWebAppInsights } from "./service/app-insights";
import { loadApp } from "./apps/shell";
import { apiGetCurrentUser, apiGetEnabledFeatures } from "./service/http";

export const SHELL_APP = "@sciworm/shell";
export const SCIWORM_APP = "@sciworm/sciworm";
export const SCIWORM_BIOLINK = "@sciworm/sciworm-biolink";

function removeLoader() {
  Array.from(document.getElementsByClassName("single-spa-loader")).forEach((it) => it.remove());
}

function appChangeHandler(e: CustomEvent) {
  const app = e.detail.appsByNewStatus.MOUNTED[0];
  if (app) {
    removeError();
    removeLoader();
    Array.from(document.head.getElementsByTagName("style")).forEach((item) => {
      const dataApp = item.getAttribute("data-app");
      if (!dataApp) {
        item.setAttribute("data-app", app);
      } else if (dataApp === app) {
        item.setAttribute("media", "all");
      } else {
        item.setAttribute("media", "not all");
      }
    });
  }
}

function errorHandler(error: AppError) {
  loadError(error.appOrParcelName, error.message);
  sciwormAppInsights?.trackException(error);
}

let sciwormAppInsights = null;

export async function startShell() {
  window.addEventListener("single-spa:app-change", appChangeHandler);
  window.addEventListener("single-spa:before-routing-event", directAccessRoutingHandler);

  try {
    const config = await auth.config();
    await auth.initialize(config);
    sciwormAppInsights = await initializeWebAppInsights();
    const user = await apiGetCurrentUser();
    const features = await apiGetEnabledFeatures();
    registerApplication({
      name: SCIWORM_BIOLINK,
      app: () => loadApp(SCIWORM_BIOLINK),
      activeWhen: sciwormBiolinkLocation,
      customProps: {
        sciwormAuth: auth,
        sciwormAppInsights,
        user,
        features,
      },
    });
    registerApplication({
      name: SCIWORM_APP,
      app: () => loadApp(SCIWORM_APP),
      activeWhen: notSciwormBiolinkLocation,
      customProps: {
        sciwormAuth: auth,
        sciwormAppInsights,
        user,
        features,
      },
    });
    addErrorHandler(errorHandler);
    start({
      // apparently needed for Vue3 router
      urlRerouteOnly: true,
    });
  } catch (e) {
    console.log(e.stack);
    loadError(SHELL_APP, e.message);
    sciwormAppInsights?.trackException(e);
  }
}

// for tests only
export async function cleanUp() {
  sciwormAppInsights = null;
  await unregisterApplication(SCIWORM_APP);
  await unregisterApplication(SCIWORM_BIOLINK);
  removeErrorHandler(errorHandler);
  window.removeEventListener("single-spa:app-change", appChangeHandler);
  window.removeEventListener("single-spa:before-routing-event", directAccessRoutingHandler);
  window.location.hash = "";
}
