export function loadError(app: string, message: string) {
  removeError();
  const el = document.createElement("div");
  el.setAttribute("id", `single-spa-error:${app}`);
  el.className += "single-spa-error";
  el.innerHTML = errorHTML(app, message);
  document.body.appendChild(el);
}

export function removeError() {
  Array.from(document.getElementsByClassName("single-spa-error")).forEach((it) => it.remove());
}

function errorHTML(app: string, message: string) {
  return `
        <div class="shell-error-container">
            <div class="shell-error-dialog">
                <div class="shell-error-icon">!</div>
                <div>
                    <div class="shell-error-title">Error ${app}</div>
                    <div id="single-spa-error-message">${message}</div>
                </div>
            </div>
        </div>
    `;
}
