import { navigateToUrl } from "single-spa";

const PROC_TCN = /^PT[0-9]+/;
const STEP_TCN = /^ST[0-9]+/;
const PLATE = /^P[0-9]+/;
const STANDARD_VARIANT = /^V[0-9]+[A-Z]*[0-9]+/;
const ANTIBODY_VARIANT = /^AB[0-9]+[A-Z]*[0-9]+/;
const SAMPLE = /^S[0-9]+[A-Z]*[0-9]+/;
const AB_CHAIN = /^CH[0-9]+[A-Z]*[0-9]+/;

function getPathName(evt: CustomEvent) {
  const url = new URL(evt.detail.newUrl);
  return url.pathname.substring(1);
}

export function directAccessRoutingHandler(evt: CustomEvent) {
  const lookupId = getPathName(evt);

  if (!lookupId) {
    return;
  }

  // ProcTcn
  if (PROC_TCN.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/proctcndetails/${lookupId}`);
    return;
  }

  // StepTcn
  if (STEP_TCN.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/steptcndetails/${lookupId}`);
    return;
  }

  // Plate
  if (PLATE.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/platedetails/${lookupId}`);
    return;
  }

  // Standard Variant
  if (STANDARD_VARIANT.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/variantdetails/${lookupId}`);
    return;
  }

  // Antibody Variant
  if (ANTIBODY_VARIANT.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/variantdetails/${lookupId}`);
    return;
  }

  // Sample
  if (SAMPLE.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/sampledetails/${lookupId}`);
    return;
  }

  // Ab Chain
  if (AB_CHAIN.test(lookupId)) {
    navigateToUrl(`/#/modules/workflow/abchaindetails/${lookupId}`);
    return;
  }
}
